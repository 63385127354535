import React, { useState, useEffect } from "react"

import { Flex, Text, Select, Link } from "@chakra-ui/core"
import { Box } from "@chakra-ui/core"
import { FaMap } from "react-icons/fa"
import _ from "lodash"

import ModalCriteres from "./ModalCriteres"
import ModalMethodo from "./ModalMethodo"
import Carte from "./carte"
import Radar from "./radar"
import data from "./dataset/data.json"
import territoires from "./dataset/territoires.json"
import Position from "./position"
import startImg from "../../images/37255.jpg"

export function preparerDatasetEnfants(currentCodzone) {
  const palettes = [
    "rgb(4,102,134)",
    "rgb(0,156,180)",
    "rgb(243,219,173)",
    "rgb(225,164,51)",
  ]
  const intervalles = [9.5, 13, 15.8]

  const dataFranceMetro = {
    codzone: "FM",
    taux: 13.0321401209065,
    d1: 28.1002486125778,
    d2: 10.3746585490246,
    d3: 11.7235618449419,
    d4: 7.36397183495114,
    d5: 22.9713818602065,
    d6: 19.7626530331223,
    d7: 30.7488128528667,
    d8: 23.5671063134927,
  }

  const labels = {
    d1: "Au moins un parent au chômage/contrat précaire",
    d2: "Tous les parents sans diplôme",
    d3: "Tous les parents sans emploi",
    d4: "Vivant en locatif privé collectif ancien",
    d5: "Vivant en logement social ou précaire",
    d6: "Vivant en famille monoparentale",
    d7: "Vivant en famille nombreuse",
    d8: "Vivant en logement sur-peuplé",
  }

  const datum = _.find(data, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForRadar = []
  let maxValueForRadar = undefined
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForRadar = _.keys(datum)
      .filter(key => key !== "codzone" && key !== "taux")
      .map(key => {
        const valDep =
          Math.floor((datum[key] / dataFranceMetro[key]) * 1000) / 10

        if (valDep > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = valDep
        }
        if (dataFranceMetro[key] > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = dataFranceMetro[key]
        }
        return {
          critere: `${labels[key]}`,
          [`${territoire.nom}`]: valDep,
          "France Métropolitaine":
            Math.floor((dataFranceMetro[key] / dataFranceMetro[key]) * 1000) /
            10,
        }
      })
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] <= datum.taux
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
    if (maxValueForRadar) {
      maxValueForRadar = maxValueForRadar * 1.2
    }
  }
  return {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,maxValueForRadar
  }
}

function AppCore({
  codzone: currentCodzone,
  setCodzone,
  selectContainer,
  headerContainer,
}) {
  const [isFirstDisplayed, setFirstDisplay] = useState(false)

  useEffect(() => {
    if (isFirstDisplayed) {
      window.scrollTo({
        top:
          selectContainer.current.offsetTop -
          headerContainer.current.offsetHeight,
        behavior: "smooth",
      })
    }
  }, [isFirstDisplayed, headerContainer, selectContainer])

  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,maxValueForRadar
  } = preparerDatasetEnfants(currentCodzone)

  return (
    <>
      <Box ml={10} mr={10} ref={selectContainer}>
        <Box>
          {currentCodzone && territoire ? (
            <>
              <Text fontSize="sm" fontWeight="600">
                <Box as={FaMap} display="inline" m={2} />
                Vous consultez les données sur
                <Select
                  value={currentCodzone}
                  placeholder="Sélectionnez un département"
                  onChange={item => {
                    if (!isFirstDisplayed) {
                      setFirstDisplay(true)
                    }
                    setCodzone(item.target.value)
                  }}
                >
                  {territoires.map(({ codzone, nom }) => (
                    <option key={codzone} value={codzone}>
                      {nom}
                    </option>
                  ))}
                </Select>
              </Text>
            </>
          ) : (
            <Flex alignItems="center">
              <Flex width="10%">
                <img
                  src={startImg}
                  alt="lecture"
                  style={{ margin: "auto" }}
                ></img>
              </Flex>
              <Flex flexGrow={1} direction="column">
                <Text fontWeight="800">
                  Découvrez le baromètre Compas pour votre département
                </Text>
                <Select
                  placeholder="Sélectionnez un département"
                  onChange={item => {
                    if (!isFirstDisplayed) {
                      setFirstDisplay(true)
                    }
                    setCodzone(item.target.value)
                  }}
                >
                  {territoires.map(({ codzone, nom }) => (
                    <option key={codzone} value={codzone}>
                      {nom}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          )}
        </Box>
        {currentCodzone && territoire ? (
          <>
            <Text fontSize="lg" fontWeight="600" mt={5}>
              Taux de fragilité des enfants = {Math.floor(datum.taux * 10) / 10}
              %
            </Text>
            <Text fontSize="md">Référence nationale = 13%</Text>

            <Flex direction="row" wrap="wrap">
              <Flex minWidth="600px" height="400px">
                <Radar
                  data={dataForRadar}
                  maxValue={maxValueForRadar * 1.1}
                  keys={["France Métropolitaine", territoire.nom]}
                  labels={labels}
                  zoneColor={zoneColor}
                />
              </Flex>

              <Flex direction="column">
                <Text>En comparaison avec les autres départements</Text>
                <Carte palettes={palettes} intervalles={intervalles} />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex>
                <Box mr={5}>
                  <ModalMethodo />
                </Box>
                <ModalCriteres />
              </Flex>
              <Flex>
                <Text fontSize="xs">
                  Sources :{" "}
                  <Link href="http://www.le-compas.fr" isExternal>
                    Compas©
                  </Link>
                  , Insee 2016
                </Text>
              </Flex>
              <Flex>
                <Position
                  palettes={palettes}
                  intervalles={intervalles}
                  note={datum.taux}
                />
              </Flex>
            </Flex>
          </>
        ) : null}
      </Box>
    </>
  )
}

export default AppCore
