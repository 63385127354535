import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Start from "../components/fragilite-des-enfants/start"
import AppCore from "../components/fragilite-des-enfants/appCore"

import imgFragiliteEnfant from "../images/fragilite-enfant.svg"

const ComparateurFragiliteDesEnfants = () => {
  const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)

  return (
    <Layout
      title="L’indice de fragilité des enfants "
      headerContainer={headerContainer}
      img={imgFragiliteEnfant}
    >
      <SEO title="Baromètre Compas - L’indice de fragilité des enfants" />
      <Start
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
      />
      <AppCore
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default ComparateurFragiliteDesEnfants
