import React from "react";

export default ({ palettes, intervalles, note }) => {
  const barW = 50;
  const barH = 20;

  const textes = intervalles.map((valeur, idx) => (
    <text
      x={(idx + 1) * barW}
      y={barH + 17}
      textAnchor="middle"
      fontSize="12px"
    >
      {valeur}
    </text>
  ));

  const legendes = palettes.map((borne, idx) => {
    let intervalle = 0;
    while (intervalle < intervalles.length && intervalles[intervalle] <= note) {
      intervalle++;
    }
    intervalle = 100;

    if (idx <= intervalle) {
      return (
        <rect
          id={idx}
          x={idx * barW}
          y={0}
          width={barW}
          height={barH}
          fill={borne}
        />
      );
    } else {
      return (
        <rect
          id={idx}
          x={idx * barW}
          y={0}
          width={barW}
          height={barH}
          fill={borne}
          opacity="0.1"
        />
      );
    }
  });

  return (
    <svg
      width="200px"
      height="40px"
      viewBox={`0 0 ${palettes.length * barW} ${barH}`}
    >
      <g transform="translate(0,-20)">
        {legendes}
        {textes}
        {/* <text x={0} y={`${barH * 2}`} fontSize={`${25}`}>
        0
      </text>
      <text
        x={`${(barW * palettes.length) / 2}`}
        y={`${barH * 2 - 15}`}
        fontSize={`${14}`}
        textAnchor="middle"
      >
        17.5
      </text>
      <text
        x={`${(barW * palettes.length) / 2}`}
        y={`${barH * 2 - 1}`}
        fontSize={`${14}`}
        textAnchor="middle"
      >
       Référence nationale
      </text>
      <text
        x={`${palettes.length * barW - 25}`}
        y={`${barH * 2}`}
        fontSize={`${25}`}
      >
        35
      </text> */}

        <text x={0} y={barH + 17} textAnchor="start" fontSize="12px">
          min.
        </text>
        <text
          x={palettes.length * barW}
          y={barH + 17}
          textAnchor="end"
          fontSize="12px"
        >
          max.
        </text>
      </g>
    </svg>
  );
};
