import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <Accordion allowMultiple>
    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le logement
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Stack spacing={5}>
          <Text>
            <Text as="strong">
              Être locataire d’un logement social ou collectif privé ancien
            </Text>{" "}
            approche le niveau de vie des ménages, l’absence de patrimoine
            immobilier et l’éventualité de vivre dans une copropriété dégradée
            ou du locatif privé « social de fait » ;
          </Text>
          <Text>
            La présence d’enfants (moins de 15 ans) dans un territoire traduit
            la présence de familles. Même si leurs besoins individuels de soins
            sont, en moyenne moins importants que ceux des personnes âgées, leur
            part dans la population totale (en France métropolitaine la
            population des familles représente 55% de la population totale)
            oblige le système de soins à leur apporter une réponse adaptée en
            matière de santé dans un territoire. Elle peut aussi se traduire
            dans de nombreux territoires, dans le cadre du parcours de soins
            coordonnés, par des difficultés d’intégrer la patientèle d’un
            praticien à proximité de chez soi, pour des populations qui beaucoup
            plus souvent que les autres, s’inscrivent dans des parcours
            résidentiels (30 à 40% de la population âgée de moins de 40 ans
            change de commune de résidence tous les cinq ans).
          </Text>
        </Stack>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le surpeuplement
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">Le surpeuplement du logement</Text> implique une
          difficulté pour la réalisation de ses devoirs à la maison
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          La monoparentalité
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">La monoparentalité</Text> génère l’absence d’adulte
          lorsque ce parent seul travaille et augmente les difficultés dans
          l’exercice de la parentalité (élever seul ses enfants).
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Les familles nombreuses
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
        <Text as="strong"> Une fratrie nombreuse</Text> (3 enfants ou plus) traduit une moindre
          disponibilité pour chacun d’eux lorsque les deux parents ou le parent
          seul travaille-nt.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          La précarité de l’emploi
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          La <Text as="strong">précarité de l’emploi</Text> implique difficultés économiques et
          incertitudes.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le chômage
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
        <Text as="strong">Le chômage, l’absence d’emploi</Text>, est fortement corrélé à la pauvreté et
          augmente encore davantage les incertitudes quant à l’avenir économique
          du ménage.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le diplôme des parents
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">L’absence de diplôme des parents</Text> implique davantage de difficultés
          dans le soutien scolaire apporté à ses enfants.
        </Text>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)
