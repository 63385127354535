import React from "react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
  List,
  ListItem,
} from "@chakra-ui/core"

function DisplayModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} size="sm">
        Comprendre les valeurs du graphique
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comprendre les valeurs du graphique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5} fontSize="sm">
              <Text>
                Les 7 critères choisis pour composer le baromètre de fragilité
                des enfants sont déclinés en 8 indicateurs distincts. Chaque
                indicateur est un pourcentage calculé sur chacun des territoires
                observés. Ainsi, nous calculons la part des enfants répondant
                aux critères suivants :
              </Text>
              <List styleType="disc">
                <ListItem>
                  dont au moins un parent est au chômage ou en contrat précaire,
                </ListItem>
                <ListItem>dont tous les parents sont sans diplôme,</ListItem>
                <ListItem>dont tous les parents sont sans emploi,</ListItem>
                <ListItem>
                  vivant en locatif privé dans collectif ancien,
                </ListItem>
                <ListItem>vivant en logt social ou précaire,</ListItem>
                <ListItem>vivant en famille monoparentale,</ListItem>
                <ListItem>vivant en famille nombreuse,</ListItem>
                <ListItem>vivant en logement sur-peuplé.</ListItem>
              </List>
              <Text>
                La grande majorité des enfants rencontrent l’un ou l’autre de
                ces critères. La fragilité n’apparaît donc pas lorsqu’un seul
                facteur est rempli, mais sitôt qu’ils se cumulent. Ainsi, dès
                qu’un enfant <Text as="em">cumule</Text> 4 de ces 7 critères, il
                peut être considéré comme fragile .
              </Text>
              <Text>
                Les 8 axes du graphique reprénsentent ces 8 indicateurs, <Text as="strong">chacun
                rapporté à la moyenne métropolitaine (valeur 100)</Text> : <Text as="em">une valeur de 150 signifie que l'indicateur est 1,5 plus élevé que la moyenne métropolitaine.</Text>.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default DisplayModal
