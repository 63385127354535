import React from "react"
import { Flex, Text, Box, Stack, Icon, Link } from "@chakra-ui/core"

import image from "../../images/lectureLivre.png"
import startImg from "../../images/image_CompaszOOm25-22Avril2020.png"

export default ({ codzone, setCodzone, selectContainer }) => {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex flex={10} direction="column">
          <Stack spacing={5}>
            <Text fontSize="lg" fontWeight="600">
              Repérer les besoins des familles et enfants les plus fragiles
            </Text>
            <Text>
              Les effets du confinement sur les enfants et les jeunes seront
              différents selon les ménages ; telle est l’analyse relayée par
              l’ensemble des acteurs sociaux. Des familles modestes ne pourront
              pas accompagner leurs enfants de la même manière que celles
              disposant de revenus supérieurs, de logements plus grands ou d’une
              plus grande aptitude au soutien scolaire.
            </Text>
            <Text>
              Depuis six années déjà, le {" "}
              <Link href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </Link>{" "}
              alerte sur ces questions de{" "}
              <Text as="em">fragilité des enfants</Text> et a pour cela créé un{" "}
              <span style={{ fontWeight: "600" }}>baromètre©Compas</span> :
              l’indice de fragilité des enfants.
            </Text>
            <Text>
              Dans le cadre de travaux d’évaluation des dispositifs de réussite
              éducative, cet indice a été construit en collaboration avec des
              professionnels de terrain, dans l’objectif de{" "}
              <Text as="mark">
                mesurer les besoins d’un territoire et d’en suivre les
                évolutions
              </Text>{" "}
              dans le temps. Il peut être utilisé pour toutes les tranches
              d’âge, de la toute petite enfance à la fin de l’adolescence, il
              est déclinable sur tous les territoires, du quartier au niveau
              national.
            </Text>
          </Stack>
        </Flex>
        <Flex flex={6} direction="column">
          <Flex alignItems="center" m={0}>
            <img
              src={image}
              width={"50%"}
              alt="lecture"
              style={{ margin: "auto" }}
            ></img>
          </Flex>

          <Box
            m={5}
            as="a"
            rel="noopener noreferrer"
            title="Compas zOOm n°24"
            href="http://www.lecompas.fr/doc/CompaszOOm24-15Avril2020.pdf"
            target="_blank"
          >
            <Text textAlign="center" mb={2}>
              Cliquez et Consultez
              <br /> le{" "}
              <span style={{ fontWeight: "600" }}>numéro spécial</span> Compas
              zOOm consacré aux fragilités des enfants
              <Icon name="download"></Icon>
            </Text>

            <img
              src={startImg}
              alt="logo Compas Zoom"
              style={{ margin: "auto" }}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}
